/**
 * This file contains only PUBLIC API keys that are safe to expose in the client.
 * These keys are typically read-only and cannot be used to access or modify sensitive data.
 *
 * - Private keys should be stored in .env files
 * - If unsure whether a key should be public, treat it as private
 *
 * Example of safe public keys:
 * - Public read-only API keys
 * - Client-side only API keys
 * - Public feature flags
 */

export const STATSIG_CLIENT_PORTAL_SDK_KEY =
  'client-5fCGdIqrAzT9ix4tgSeBTzVlMkS11f0ctfMcAVqMD9v';
